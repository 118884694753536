exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brand-home-js": () => import("./../../../src/pages/brand_home.js" /* webpackChunkName: "component---src-pages-brand-home-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-brandlogin-js": () => import("./../../../src/pages/brandlogin.js" /* webpackChunkName: "component---src-pages-brandlogin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-earn-js": () => import("./../../../src/pages/earn.js" /* webpackChunkName: "component---src-pages-earn-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgetpassword-js": () => import("./../../../src/pages/forgetpassword.js" /* webpackChunkName: "component---src-pages-forgetpassword-js" */),
  "component---src-pages-historybrand-js": () => import("./../../../src/pages/historybrand.js" /* webpackChunkName: "component---src-pages-historybrand-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-scanqr-js": () => import("./../../../src/pages/scanqr.js" /* webpackChunkName: "component---src-pages-scanqr-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-termscondition-js": () => import("./../../../src/pages/termscondition.js" /* webpackChunkName: "component---src-pages-termscondition-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blogdetail-js": () => import("./../../../src/templates/blogdetail.js" /* webpackChunkName: "component---src-templates-blogdetail-js" */),
  "component---src-templates-branddetail-js": () => import("./../../../src/templates/branddetail.js" /* webpackChunkName: "component---src-templates-branddetail-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-packages-js": () => import("./../../../src/templates/packages.js" /* webpackChunkName: "component---src-templates-packages-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-voucher-js": () => import("./../../../src/templates/voucher.js" /* webpackChunkName: "component---src-templates-voucher-js" */)
}

